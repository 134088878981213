.gallery {
  background-color: #F3F3F3;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &--before {
    position: absolute;
    left: 0;
    height: 156px;
    background-color: #D71049;
    top: auto;
    width: 90%;
    bottom: 55%;
    z-index: -1;
    @include tablet {
      bottom: 50%; }
    @include desktop {
      top: calc(50% - 78px);
      width: 50%; } }
  &--after {
    position: absolute;
    right: 0;
    top: 45%;
    width: 90%;
    height: 255px;
    background-color: #F5D212;
    z-index: -1;
    @include tablet {
      top: 50%; }
    @include desktop {
      top: calc(50% - 127.5px);
      width: 50%; } }
  @include phone {
    padding: 0 0 37px; }
  @include tablet {
    padding: 20px 0 40px; }
  @include desktop {
    padding: 2px 0 60px; }
  &__block {
    height: 450px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 100;
    a {
      display: block;
      position: relative;
      background-color: #fff;
      &:hover {
        .gallery__text {
          opacity: 1; } }
      &:not(:first-child) {
        display: none; }
      img {
        display: block;
        width: 100%;
        height: auto;
        height: 450px;
        object-fit: cover; } }
    + .gallery__block {
      margin-top: 30px; } }
  &__text {
    position: absolute;
    transition: all .3s ease;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px;
    color: #fff;
    padding: 230px 10px 36px;
    border-radius: 10px;
    text-align: center;
    @include desktop {
      opacity: 0; }
    h4 {
      font-size: 20px;
      line-height: 1.25em;
      margin-bottom: 22px;
      &::before {
        content: '"'; }
      &::after {
        content: '"'; } }
    p {
      font-size: 12px;
      line-height: 1.250em;
      margin-bottom: 13px; }
    span {
      display: block;
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      line-height: 1em;
      padding: 12px;
      border: 1px solid #fff;
      border-radius: 20px;
      transition: all .3s ease;
      &:hover {
        background-color: #F3F3F3;
        color: #000; } }
    &::before {
      content: '';
      width: 60px;
      height: 72px;
      left: calc(50% - 30px);
      top: 112px;
      position: absolute;
      background: url('../img/logo.png') center no-repeat;
      background-size: contain; }
    &::after {
      content: '';
      width: calc(100% - 20px);
      height: 1px;
      position: absolute;
      left: 10px;
      right: 10px;
      top: 214px;
      background-color: #fff; } }
  &__slider {
    position: relative;
    z-index: 1; } }
.swiper {
  &-slide {
    &--small {
      .gallery__block {
          height: 210px;
          img {
            height: 210px; } }
      .gallery__text {
        padding: 63px 10px 12px;
        &::before {
          width: 26px;
          height: 32px;
          left: calc(50% - 13px);
          top: 10px;
          position: absolute;
          background: url('../img/logo.png') center no-repeat;
          background-size: contain; }
        &::after {
          top: 52px; }
        h4 {
          margin-bottom: 6px; }
        p {
          margin-bottom: 9px; } } } }
  &-wrapper {
    margin-bottom: 65px; }
  &-pagination {
    &-bullet {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 3px solid #D71049;
      box-sizing: border-box;
      opacity: 1;
      background-color: transparent;
      &-active {
        background-color: #D71049; } } } }
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0; }

.mfp-content {
  max-width: 1111px!important; }

.mfp-top-bar {
  position: relative; }
.mfp-title {
  padding: 18px 30px 16px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  font-family: $font;
  color: #000;
  text-align: left;
  h3 {
    font-size: 16px;
    margin-bottom: 3px;
    line-height: 1.250em; }
  p {
    font-size: 12px;
    line-height: 1.250em; } }

.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
  right: 20px;
  cursor: pointer!important;
  top: 13px;
  font-size: 40px;
  color: #000;
  width: auto;
  height: auto;
  line-height: 1em;
  margin: 0;
  padding: 0;
  opacity: 1;
  &:hover {
    color: $color1; } }

.mfp-middle-bar {
  position: relative;
  max-height: 80vh;
  overflow: hidden;
  &::before {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 150px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1; }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
    object-fit: cover;
    padding: 0;
    margin: 0; } }

.mfp-bottom-bar {
  margin: 0; }

.mfp-arrow {
  position: absolute;
  top: auto;
  bottom: 33px;
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff!important;
  opacity: 1;
  &:hover, &:focus {
    opacity: .7; }
  &::before {
    content: none; }
  &::after {
    content: none; }
  &-left {
    left: auto;
    right: 51%;
    &::before {
      content: '';
      margin: 0;
      width: 10px;
      height: 10px;
      background-color: transparent;
      position: absolute;
      left: calc(50% - 4px);
      top: calc(50% - 5px);
      transform: rotate(45deg);
      border: 2px solid #fff;
      border-right: none;
      border-top: none;
      opacity: 1; } }
  &-right {
    right: auto;
    left: 51%;
    &::before {
      content: '';
      margin: 0;
      width: 10px;
      height: 10px;
      background-color: transparent;
      position: absolute;
      left: calc(50% - 8px);
      top: calc(50% - 5px);
      transform: rotate(45deg);
      border: 2px solid #fff;
      border-left: none;
      border-bottom: none;
      opacity: 1; } } }
.mfp-counter {
  right: calc(55% + 50px);
  left: auto;
  top: auto;
  line-height: 1em;
  height: auto;
  bottom: 46px;
  font-family: $font;
  font-size: 20px;
  color: #fff;
  opacity: 1;
  z-index: 2000;
  span {
    font-weight: 700; } }
