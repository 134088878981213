.nav-btn {
  width: 27px;
  height: 24px;
  position: relative;
  background-color: transparent;
  outline: none;
  &__line {
    position: absolute;
    width: 27px;
    height: 4px;
    left: 0;
    top: calc(50% - 2px);
    border-radius: 2px;
    background-color: transparent;
    transition: all .3s ease;
    &::before, &::after {
      content: '';
      position: absolute;
      width: 27px;
      height: 4px;
      left: 0;
      top: 0;
      background-color: $navBtnColor;
      border-radius: 2px;
      transition: all .3s ease; } }
  &--open {
    .nav-btn__line {
      background-color: $navBtnColor;
      &::before {
        top: -9px; }
      &::after {
        top: 9px; } }
    &:hover, &:focus {
      .nav-btn__line {
        width: 20px; } } }
  &--close {
    .nav-btn__line {
      &::before, &::after {
        height: 6px; }
      &::before {
        transform: rotate(45deg); }
      &::after {
        transform: rotate(-45deg); } }
    &:hover, &:focus {
      .nav-btn__line {
        transform: rotate(90deg); } } } }
