.articles-calendar {
  @include phone {
    padding: 0 0 30px; }
  @include tablet {
    padding: 35px 0 30px; }
  @include desktop {
    padding: 0 0 40px; } }
.articles {
  z-index: 500;
  position: relative;
  &-slider {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include phone {
      margin-bottom: 20px; } }
  &__date {
    display: flex;
    margin-bottom: 20px;
    @include desktop {
      margin-bottom: 30px; }
    div {
      position: relative;
      &:not(:last-of-type) {
        margin-right: 30px; } } }
  &__days {
    flex-shrink: 0;
    width: auto;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    width: calc(100% - 130px);
    @include phone {
      width: calc(100% - 102px); }
    button {
      display: inline-block;
      flex-shrink: 0;
      font-family: $font;
      font-size: 16px;
      padding: 12px;
      line-height: 1em;
      background-color: #F3F3F3;
      border-radius: 20px;
      transition: all .3s ease;
      text-align: center;
      @include phone {
        width: calc( 110px);
        padding: 12px 5px; }
      @include r(360) {
        font-size: 12px;
        width: 94px; }
      @include tablet {
        width: 152px; }
      @include desktop {
        width: 160px; }
      &:hover {
        color: #fff;
        background-color: #A20331; }
      &:disabled {
        background-color: #fff;
        color: #000;
        cursor: default; }
      &.active {
        background-color: #D71049;
        color: #fff;
        font-weight: 700;
        &:disabled {
          background-color: #fff;
          color: #000; } } } }
  &__controls {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    &--prev {
      padding-right: 17px;
      @include phone {
        padding-right: 3px; } }
    &--next {
      padding-left: 17px;
      @include phone {
        padding-left: 3px; } }
    .button-controls {
      display: block;
      flex-shrink: 0;
      width: 48px;
      height: 40px;
      background-color: #D71049;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      position: relative;
      &:hover {
        background-color: #A20331; }
      &:active {
        background-color: #000; }
      &:not(:last-child) {
        margin-right: 5px; }
      &:disabled {
        background-color: #f3f3f3;
        cursor: default; }
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } } } }
.drop-list {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  max-height: 190px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 15px;
    transition: all .3s ease; }
  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background: $customFooterScrollbarBG; }
  &::-webkit-scrollbar-thumb {
    background: $customFooterScrollbarColor;
    border-radius: 15px; }
  &::-webkit-scrollbar-thumb:hover {
    background: $customFooterScrollbarColorHover;
    cursor: pointer; }
  &__button {
    font-family: $font;
    font-size: 16px;
    line-height: 1em;
    border-radius: 20px;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    transition: all .3s ease;
    padding: 11px 30px 11px 20px;
    min-width: 120px;
    text-align: left;
    position: relative;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #000000 transparent transparent transparent;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%); }
    &:hover, &:active {
      border: 1px solid #A20331; }
    @include phone {
      width: 100%;
      max-width: 150px; }
    @include tablet {
      min-width: 210px; }
    @include desktop {
      min-width: 160px; } }
  button {
    font-family: $font;
    width: 100%;
    display: block;
    text-align: left;
    padding: 7px 10px;
    font-size: 14px;
    background-color: transparent;
    &.active {
      font-weight: 700; } } }

.article {
  &__title {
    font-size: 20px;
    line-height: 1.335em;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include phone {
      font-size: 18px; } }
  &__text {
    @include phone {
      font-size: 14px;
      line-height: 1.71em; }
    @include tablet {
      font-size: 16px;
      line-height: 1.87em; }
    @include desktop {
      font-size: 18px;
      line-height: 1.67em; } } }
