.footer {
  background-color: $footerBGColor;
  color: $footerTextColor;
  & > .container {
    z-index: 500;
    & > .row {
      & > .col-12 {
        z-index: 500;
        &:last-of-type {
          z-index: 300; } } } }
  &__info {
    display: block;
    color: $footerLinkColor;
    padding-left: 24px;
    font-size: 16px;
    line-height: 1.500em;
    margin-bottom: 10px;
    @include phone {
      font-size: 14px;
      line-height: 1.714em;
      margin-bottom: 11px; }
    &--location {
      background: url('../img/map.svg') no-repeat;
      background-size: 12px 18px;
      background-position: 2px 2px; }
    &--phone {
      background: url('../img/phone.svg') no-repeat;
      background-size: 10px 18px;
      background-position:  3px 2px; }
    &--email {
      background: url('../img/email.svg') no-repeat;
      background-size: 16px 18px;
      background-position: 0 2px; }
    &--title {
      font-weight: 700;
      padding-left: 0;
      @include desktop {
        padding-left: 24px; } } }
  &__title {
    @include phone {
      font-size: 14px;
      margin-bottom: 18px; }
    @include tablet {
      font-size: 16px;
      margin-bottom: 15px; }
    @include desktop {
      font-size: 18px;
      margin-bottom: 13px; }
    &--center {
      text-align: center; }
    &--city {
      @include tablet {
        margin-bottom: 13px; }
      @include desktop {
        text-align: center; } }
    &-city {
      display: block;
      margin-bottom: 8px; } }
  &-info {
    padding: 10px 0;
    border-top: 1px solid $footerCopyrightBorderColor;
    font-size: 12px;
    background-color: #fff;
    color: #000;
    position: relative; }
  &-copyright {
    padding: 14px 0;
    border-top: 1px solid $footerCopyrightBorderColor;
    color: $footerCopyrightFontColor;
    font-size: 12px;
    position: relative; }
  &__creator {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin-left: 9px; } }
  &__social {
    display: flex;
    justify-content: flex-end;
    li {
      &:not(:first-child) {
        margin-left: 36px; }
      &:last-child {
        margin-right: 14px;
        @include phone {
          margin-right: 0; } } }
    a {
      &:hover, &:focus {
        svg {
          fill: $color1; } } }
    svg {
      display: block;
      fill: #000;
      transition: all .3s ease; } } }


