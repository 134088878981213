.entry {
  @include phone {
    padding: 10px 0 30px; }
  @include tablet {
    padding: 5px 0 70px; }
  @include desktop {
    padding: 30px 0 40px; }
  &__article {
    margin-bottom: 26px;
    @include phone {
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 1.714em; }
    @include tablet {
      font-size: 16px;
      line-height: 1.875em; }
    @include desktop {
      font-size: 18px;
      line-height: 1.667em; } } }
