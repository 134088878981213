// BOOTSTRAP GRID
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

// CUSTOM
$font: 'Montserrat', sans-serif;
$color1: #D71049;
$color2: #fff;
$color3: #F5D212;
$color4: #A20331;
$color5: #000;
$color6: #CCAD01;
$color7: #F3F3F3;
$color8:  #DADADA;
$color9: #9B9B9B;
$color10: #C4C4C4;

// BODY VARIABLES
$bodyFontFamily: $font;
$bodyFontColor: $color5;
// HEADER COLOR GRID
$navBtnColor: $color2;
$navLinkColor: $color2;

$langSwitchColor: $color2;
$langSwitchColorHover: $color3;


// BUTTONS
$buttonFontFamily: $font;

// BUTTONS JOIN
$buttonJoinBGColor: $color1;
$buttonJoinBGColorHover: $color4;
$buttonJoinBGColorActive: $color5;

$buttonJoinFontColor: $color2;

$buttonJoinBorderColor: $color3;

// BUTTONS DOWNLOAD
$buttonDownloadBGColor: $color3;
$buttonDownloadBGColorHover: $color6;
$buttonDownloadBGColorActive: $color1;

$buttonDownloadFontColor: $color2;

$buttonDownloadBorderColor: $color3;

// BUTTONS TABS
$buttonTabsBGColor: $color2;
$buttonTabsBGColorHover: $color7;
$buttonTabsBGColorInTabHover: $color4;
$buttonTabsBGColorActive: $color1;
$buttonTabsBGColorActiveHover: $color4;

$buttonTabsFontColor: $color5;
$buttonTabsFontColorActive: $color2;

$buttonTabsBorderColor: $color3;


// FIRST SCREEN -- INTRO
$introTitleColor: $color2;

// TITLE
$titleColor: $color5;
$titleBorderColor: $color3;
$titleColorWhite: $color2;


// ABOUT
$bgColorPseudo: $color1;
$aboutGalleryItemBGOdd: $color1;
$aboutGalleryItemBGEven: $color3;

// PROJECTS
$projectsInfoBGColor: rgba($color2, .9);

// FORM
$inputBGcolor: $color2;
$inputBorderColor: $color8;
$inputFontFamily: $font;
$inputFontColor: $color5;
$inputPlaceholderColor: $color9;
$labelColor: $color2;
$checkboxFontColor: $color2;
$checboxBorderColor: $color2;
$checboxBGColor: transparent;


// FOOTER
$footerBGColor: $color5;
$footerTextColor: $color2;
$footerLinkColor: $color2;

$footerCustomSelectBorder: $color2;
$footerCustomSelectBGColor: transparent;
$footerCustomSelectFontColor: $color2;
$footerCustomSelectFontFamily: $font;

$footerCopyrightBorderColor: $color9;
$footerCopyrightFontColor: $color10;


// CUSTOM FOOTER SCROLLBAr
$customFooterScrollbarBG: $color7;
$customFooterScrollbarColor: $color1;
$customFooterScrollbarColorHover: $color4;
