.title {
  color: $titleColor;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.208em;
  font-weight: 700;
  margin-bottom: 40px;
  margin-bottom: 35px;
  padding: 25px 0;
  @include phone {
    font-size: 18px;
    line-height: 1.222em;
    margin-bottom: 20px; }
  @include desktop {
    padding: 40px 0 25px; }
  &--border {
    border-bottom: 2px solid $titleBorderColor; }
  &--icon {
    background: url('../img/title-icon.svg') no-repeat 4px center;
    padding-left: 76px;
    @include phone {
      padding: 22px 0 22px 76px; } }
  &--white {
    color: $titleColorWhite; }
  &--projects {
    @include desktop {
      padding: 40px 0 14px;
      margin-bottom: 40px; } }
  &--gallery {
    @include desktop {
      padding: 42px 0 25px;
      margin-bottom: 27px; } }
  &--entry {
    @include desktop {
      padding: 37px 0 40px;
      margin-bottom: 42px; } }
  &--art {
    @include desktop {
      margin-bottom: 30px; } }
  &--form {
    @include desktop {
      padding: 41px 0 16px;
      margin-bottom: 38px; } }
  &--footer {
    font-size: 20px;
    padding: 23px 0 10px;
    margin-bottom: 13px; } }
