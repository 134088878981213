.scroll-down {
  display: block;
  height: 67px;
  width: 100%;
  max-width: 29px;
  margin: auto auto 17.5px;
  animation-play-state: paused;
  text-align: center;
  svg {
    fill: #fff;
    transition: all .3s ease; }
  &:hover,
  &:focus {
    animation: scrollPulse 1s infinite; }
  &:hover {
    svg {
      fill: $color1; } } }

@keyframes scrollPulse {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0); } }
