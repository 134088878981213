.lang-switch {
  margin-right: 53px;
  display: flex;
  a {
    font-size: 18px;
    color: $langSwitchColor;
    transition: all .3s ease;
    &:hover, &::before {
      color: $langSwitchColorHover; } }
  li {
    &:not(:last-of-type) {
      padding-right: 3px;
      &::after {
        content: '|';
        font-weight: 400;
        color: $langSwitchColor; } } }
  &--active {
    font-weight: 700;
    a {
      &:hover, &:focus {
        cursor: default;
        color: $langSwitchColor; } } } }
