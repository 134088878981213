.about {
  overflow: hidden;
  @include phone {
    padding: 20px 0 30px; }
  @include tablet {
    padding: 40px 0 60px; }
  @include desktop {
    padding: 80px 0 50px; }
  &__image {
    position: relative;
    @include phone {
      margin-bottom: 40px; }
    @include tablet {
      margin-bottom: 60px; }
    @include desktop {
      margin-bottom: 80px; }
    img {
      display: block;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 100%; }
    &::before {
      content: '';
      position: absolute;
      background-color: $bgColorPseudo;
      top: 50%;
      transform:  translateY(-50%);
      @include phone {
        width: 15px;
        height: 123px;
        right: -15px; }
      @include tablet {
        width: 39px;
        height: 274px;
        right: -39px; }
      @include desktop {
        width: 48px;
        right: -48px;
        height: 160px; } } }
  &__text {
    @include phone {
      font-size: 14px;
      line-height: 1.714em; }
    @include tablet {
      font-size: 16px;
      line-height: 1.875em; }
    @include desktop {
      font-size: 18px;
      line-height: 1.667em; }
    p {
      &:not(:last-child) {
        margin-bottom: 10px; } }
    margin-bottom: 20px; }
  &__colections {
    li {
      &:not(:last-of-type) {
        margin-bottom: 20px;
        @include desktop {
          margin-bottom: 37px; } } }
    & > .col-12 {
      &:first-child {
        margin-bottom: 20px;
        @include desktop {
          margin-bottom: 0; } } }
    h3 {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 6px;
      font-size: 20px;
      line-height: 1.200em;
      @include phone {
        font-size: 18px;
        line-height: 1.222em;
        margin-bottom: 8px; }
      & + .button {
        margin-top: 30px;
        @include phone {
          margin-top: 15px; } } }
    p {
      &:not(:last-of-type) {
        margin-bottom: 10px; }
      @include phone {
        font-size: 14px;
        line-height: 1.714em; }
      @include tablet {
        font-size: 16px;
        line-height: 1.875em; }
      @include desktop {
        font-size: 18px;
        line-height: 1.667em; } } }
  &-gallery {
    @include phone {
      margin-bottom: 25px; }
    @include tablet {
      margin-bottom: 40px; }
    @include desktop {
      margin-bottom: 55px; }
    &__item {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      &:not(:first-child) {
        margin-top: -20px;
        @include tablet {
          margin-top: -45px; }
        @include desktop {
          margin-top: 0; } }
      .about-gallery__info {
        position: relative;
        margin-top: 10px;
        @include phone {
          padding: 18px 0 10px 10px; }
        @include tablet {
          padding: 95px 0 70px 27px; }
        @include desktop {
          padding: 30px 19px 0px 27px; }
        &::before {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          background-color: $aboutGalleryItemBGEven;
          left: -10px;
          right: 0;
          top: 50%;
          transform: translateY(-55%);
          z-index: -1;
          min-height: 255px;
          @include phone {
            min-height: 110px; } } }
      .about-gallery__image {
        z-index: 2;
        @include desktop {
          margin-left: -60px;
          margin-top: 30px; } }
      &:nth-of-type(odd) {
        flex-direction: row;
        text-align: right;
        .about-gallery__info {
          @include phone {
            padding: 15px 15px 10px 0; }
          @include r(360) {
            padding: 10px 5px 20px 0; }
          @include tablet {
            padding: 100px 38px 70px; }
          @include desktop {
            padding: 30px 38px 0px 0px; }
          &::before {
            left: auto;
            right: -10px;
            background-color: $aboutGalleryItemBGOdd; } }
        .about-gallery__image {
          z-index: 3;
          @include desktop {
            margin-right: -30px;
            margin-left: auto;
            margin-top: -32px; } } } }
    &__info {
      z-index: 1;
      h3 {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 1.222em;
        @include phone {
          font-size: 14px;
          line-height: 1.214em; } }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.250em;
        @include phone {
          font-size: 12px; } } }
    &__image {
      flex-shrink: 0;
      @include phone {
        max-width: 150px; }
      @include tablet {
        max-width: 348px; }
      img {
        display: block;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); } } } }
