.contact-form {
  background: url('../img/form-bg.jpg') center left no-repeat;
  background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, .7) 0%, rgba(255, 255, 255, 0) 100%); }
  @include phone {
    padding: 0 0 25px;
    background: url('../img/form-bg-phone.jpg') center left no-repeat;
    background-size: cover; }
  @include tablet {
    padding: 25px 0 70px; }
  @include desktop {
    padding: 10px 0 65px; } }
