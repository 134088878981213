.modal {
  background: #fff;
  color: #000;
  width: 100%;
  border-radius: 10px;
  &--thanks {
    max-width: 410px;
    padding: 48px 30px 33px;
    .modal__content {
      text-align: center; } }
  &__content {
    h4 {
      font-size: 18px;
      line-height: 1.67em;
      font-weight: 400;
      margin-bottom: 29px; } }
  &-button {
    font-family: $font;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1em;
    padding: 18px 60px;
    font-weight: 700;
    border-radius: 25px;
    background: #FFFFFF;
    border: 1px solid #000000;
    transition: all .3s ease;
    &:hover, &:focus {
      background: #F3F3F3; } } }

.popup_close {
  position: absolute;
  right: 17px;
  top: 20px;
  font-family: $font;
  font-weight: 700;
  font-size: 32px;
  line-height: 1em;
  background-color: transparent;
  transition: all .3s ease;
  &:hover {
    color: #D71049; } }

.presentation {
  background: #fff;
  color: #000;
  width: 100%;
  border-radius: 10px;
  max-width: 80%;
  height: 80%;
  .popup_close {
    top: 0; }
  iframe {
    border-radius: 10px;
    width: 100%;
    height: 100%; } }
