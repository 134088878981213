.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    opacity: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    transition: all .6s ease; }
  &--scroll {
    &::before {
      content: '';
      opacity: 1; } }
  &__container {
    max-width: 1298px; }
  &__inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    padding: 10px 0;
    @include desktop {
      padding: 25px 0 10px; } }
  &__logo {
    @include phone {
      width: 100%; }
    @include desktop {
      margin-top: -15px; } }
  &__button {
    @include phone {
      display: none;
      width: 100%;
      text-align: center; }
    @include desktop {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); } }
  &__switches {
    display: flex;
    align-items: center;
    @include phone {
      position: absolute;
      right: 0;
      top: 35px;
      .lang-switch {
        display: none; } }
    @include desktop {
      padding-right: 36px; } } }

