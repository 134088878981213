.nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-120vh);
  transition: all .6s ease;
  &--active {
    transform: translateY(0); }
  .container {
    position: relative;
    height: 100%;
    max-width: 1226px; }
  &__button {
    position: absolute;
    top: 40px;
    right: 15px;
    @include phone {
      top: 34px; } }
  &__menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    overflow: auto;
    font-weight: 500;
    padding: 112px 0 60px;
    font-size: 16px;
    li {
        padding: 17.5px 0; }
    @include desktop {
      font-size: 18px;
      padding: 142px 0 70px; }
    a {
      color: $navLinkColor;
      display: block;
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $navLinkColor;
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
        transition: all .3s ease; }
      &:hover, &:active, &:focus {
        &::before {
          opacity: 1; } }
      &.active {
        font-weight: 700;
        &::before {
          opacity: 1; } } } } }
