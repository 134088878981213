.form {
  &__group {
    margin-bottom: 16px;
    @include phone {
      margin-bottom: 17px; }
    label.error {
      position: absolute;
      bottom: 0;
      font-size: 12px;
      color: #D71049; } }
  &__check {
    display: block;
    color: $checkboxFontColor;
    &--md-inline {
      @include phone {
        display: block; }
      display: inline-block; }
    &-input {
      appearance: none;
      position: absolute;
      &:checked {
        & + .form__check-label {
          cursor: default;
          & > .form__check-box {
            &::before {
              opacity: 1; } } } }
      &:hover, &:focus {
        &:checked {
          & + .form__check-label {
            & > .form__check-box {
              border: 1px solid $checboxBorderColor;
              transform: rotate(0); } } } } }
    &-box {
      position: absolute;
      width: 14px;
      height: 14px;
      background-color: $checboxBGColor;
      border: 1px solid $checboxBorderColor;
      border-radius: 2px;
      margin-left: -33px;
      top: calc(50% - 7px);
      box-sizing: border-box;
      transition: all .3s ease;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 40%;
        width: 8px;
        height: 5px;
        border-left: 1px solid $checboxBorderColor;
        border-bottom: 1px solid $checboxBorderColor;
        transform: translate(-50%, -50%) rotate(-45deg);
        opacity: 0;
        transition: all .3s ease; } }
    &-label {
      padding-left: 33px;
      position: relative;
      cursor: pointer;
      &:hover, &:focus {
        .form__check-box {
          border: 2px solid $checboxBorderColor;
          transform: rotate(90deg); } } } }
  &__label {
    display: block;
    margin-bottom: 12px;
    color: $labelColor;
    font-size: 14px; }
  &__control {
    display: block;
    width: 100%;
    border-radius: 10px;
    background-color: $inputBGcolor;
    border: 1px solid $inputBorderColor;
    font-family: $inputFontFamily;
    padding: 15px 10px;
    font-size: 14px;
    line-height: 1em;
    color: $inputFontColor;
    &::placeholder {
      color: $inputPlaceholderColor; } } }


