.button {
  font-family: $buttonFontFamily;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  line-height: 1em;
  box-sizing: border-box;
  text-align: center;
  transition: all .3s ease;
  &--join {
    display: block;
    background-color: $buttonJoinBGColor;
    font-size: 18px;
    color: $buttonJoinFontColor;
    text-transform: uppercase;
    border: 1px solid $buttonJoinBorderColor;
    font-weight: 700;
    border-radius: 20px;
    width: 100%;
    padding: 12px 50px;
    margin: 0 auto;
    @include phone {
      max-width: 330px; }
    @include tablet {
      min-width: 330px; }
    @include desktop {
      padding: 12px 99px;
      max-width: 350px; }
    &:hover, &:focus {
      background-color: $buttonJoinBGColorHover; }
    &:active {
      background-color: $buttonJoinBGColorActive; }
    &-small {
      min-width: auto;
      max-width: 210px;
      font-size: 14px;
      padding: 17px 15px;
      border-radius: 25px;
      @include desktop {
        max-width: 350px; } }
    &-center {
      display: block;
      margin: 0 auto; } }
  &--download {
    display: inline-block;
    background-color: $buttonDownloadBGColor;
    font-size: 18px;
    color: $buttonDownloadFontColor;
    border: 1px solid $buttonDownloadBorderColor;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 25px;
    padding: 15px 15px;
    width: 100%;
    max-width: 330px;
    @include desktop {
      width: auto;
      padding: 15px 40px;
      max-width: none; }
    &:hover, &:focus {
      background-color: $buttonDownloadBGColorHover; }
    &:active {
      background-color: $buttonDownloadBGColorActive; } }
  &--phone-center {
    @include phone {
      display: block;
      margin: 0 auto; } }
  &--center {
    max-width: 350px;
    display: block;
    margin: 0 auto; }
  &--tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $buttonTabsBGColor;
    color: $buttonTabsFontColor;
    border: 1px solid $buttonTabsBorderColor;
    font-weight: 400;
    border-radius: 30px;
    width: 100%;
    padding: 5px 20px;
    height: 60px;
    @include phone {
      font-size: 14px;
      height: 50px;
      line-height: 1.214em; }
    @include tablet {
      font-size: 16px;
      line-height: 1.250em; }
    @include desktop {
      font-size: 18px;
      line-height: 1.222em; }
    &:hover, &:focus {
      background-color: $buttonTabsBGColorHover; }
    &:active {
      background-color: $buttonTabsBGColorActive;
      color: $buttonTabsFontColorActive;
      font-weight: 700; } }
  &--small {
    font-size: 14px;
    @include desktop {
      padding: 18px 62px; } } }
