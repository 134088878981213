$light: #fff;
$border-radius: 3px;
$border-color: #ccc;

.footer-select {
  position: relative;
  z-index: 1000;
  &__button {
    width: 100%;
    border: 1px solid $footerCustomSelectBorder;
    background-color: $footerCustomSelectBGColor;
    color: $footerCustomSelectFontColor;
    font-family: $footerCustomSelectFontFamily;
    line-height: 1em;
    padding: 11px 40px 11px 10px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    @include phone {
      font-size: 14px; }
    &::after {
      content: '';
      transition: all .25s ease;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px;
      border-color: $footerCustomSelectBorder transparent transparent; } }
  &__cities {
    width: 100%;
    position: absolute;
    background-color: $footerCustomSelectBorder;
    border-radius: 10px;
    overflow: auto;
    z-index: 1000;
    max-height: 110px;
    transition: all .3s ease;
    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 15px;
      transition: all .3s ease; }
    &::-webkit-scrollbar-track {
      border-radius: 15px;
      background: $customFooterScrollbarBG; }
    &::-webkit-scrollbar-thumb {
      background: $customFooterScrollbarColor;
      border-radius: 15px; }
    &::-webkit-scrollbar-thumb:hover {
      background: $customFooterScrollbarColorHover;
      cursor: pointer; }
    button {
      padding: 9px 40px 9px 10px;
      font-family: $footerCustomSelectFontFamily;
      text-align: left;
      cursor: pointer;
      background-color: $light;
      color: #000;
      border: none;
      display: inline-block;
      width: 100%;
      font-size: 16px;
      @include phone {
        font-size: 14px; }
      &:hover, &:focus {
        background-color: darken($light, 10); }
      &.active {
        font-weight: 700; } } } }







// select
//   &#cities,
//   &.months,
//   &.years
//     appearance: none
//     opacity: 0
//     position: absolute
//     height: 1px
//     width: 1px
// .custom-footer-select
//   position: relative
//   &--show
//     .custom-footer-select__current
//       &::after
//         transform: rotate(-180deg) translateY(10px)
//     .custom-footer-select__list
//       display: block
//
//     &::after
//       content: ''
//       transition: all .25s ease
//       position: absolute
//       right: 15px
//       top: 50%
//       transform: translateY(-50%)
//       width: 0
//       height: 0
//       border-style: solid
//       border-width: 5px
//       border-color: $footerCustomSelectBorder transparent transparent transparent
//   &__list
//     width: 100%
//     position: absolute
//     background-color: $footerCustomSelectBorder
//     border-radius: 10px
//     display: none
//     max-height: 110px
//     overflow: auto
//     z-index: 1000
//     &::-webkit-scrollbar
//       width: 10px
//       border-radius: 15px
//       transition: all .3s ease
//     &::-webkit-scrollbar-track
//       border-radius: 15px
//       background: $customFooterScrollbarBG
//     &::-webkit-scrollbar-thumb
//       background: $customFooterScrollbarColor
//       border-radius: 15px
//     &::-webkit-scrollbar-thumb:hover
//       background: $customFooterScrollbarColorHover
//       cursor: pointer
//   &__item
//     padding: 9px 40px 9px 10px
//     font-family: $footerCustomSelectFontFamily
//     text-align: left
//     cursor: pointer
//     background-color: $light
//     border: none
//     display: inline-block
//     width: 100%
//     font-size: 16px
//     +phone
//       font-size: 14px
//     &:hover, &:focus
//       background-color: darken($light, 10)
