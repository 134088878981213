.projects {
  background: url(../img/project-bg.jpg) center no-repeat;
  background-size: cover;
  position: relative;
  @include phone {
    padding-bottom: 35px; }
  @include tablet {
    padding: 25px 0 60px; }
  @include desktop {
    padding: 15px 0 64px; }
  &::before {
    content: '';
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  &__tab {
    &-nav {
      display: grid;
      margin-bottom: 30px;
      @include phone {
        grid-gap: 20px; }
      @include tablet {
        grid-gap: 15px 30px;
        grid-template-columns: repeat(2, 1fr); }
      @include desktop {
        grid-gap: 10px;
        margin-bottom: 0; } }
    &-link {
      &--active {
        background-color: $buttonTabsBGColorActive;
        color: $buttonTabsFontColorActive;
        font-weight: 700;
        &:hover, &:focus {
          background-color: $buttonTabsBGColorActiveHover; } } } }
  &__image {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.2);
    img {
      display: block;
      width: 100%;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
      min-height: 35vh;
      @include phone {
        min-height: 25vh; } } }
  &__info {
    background-color: $projectsInfoBGColor;
    border-radius: 0 0 10px 10px;
    padding: 35px 30px 50px;
    min-height: 205px;
    @include phone {
      padding: 30px 30px 70px; }
    @include desktop {
      padding: 33px 37px 20px; }
    h3 {
      @include phone {
        font-size: 14px;
        line-height: 1.25em;
        margin-bottom: 4px; }
      @include tablet {
        font-size: 16px;
        line-height: 1.25em;
        margin-bottom: 6px; }
      @include desktop {
        font-size: 18px;
        line-height: 1.67em; } }
    p {
      @include phone {
        font-size: 14px;
        line-height: 1.75em; }
      @include tablet {
        font-size: 16px;
        line-height: 1.87em; }
      @include desktop {
        font-size: 18px;
        line-height: 1.67em; } } }
  &__more-btn {
    display: block;
    text-align: right;
    color: #000;
    font-weight: 700;
    transition: all .3s ease;
    span {
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #000;
        transition: all .3s ease; } }
    &:hover, &:focus {
      color: #D71049;
      span {
        &::after {
          opacity: 0; } } }
    @include phone {
      font-size: 14px;
      line-height: 1.75em; }
    @include tablet {
      font-size: 16px;
      line-height: 1.87em; }
    @include desktop {
      font-size: 18px;
      line-height: 1.67em; } } }
