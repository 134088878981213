.intro {
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 290px;
    position: absolute;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    transition: all .6s ease; }
  &__inner-wrapper {
    height: 100vh;
    video, img, iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%; }
  &__button-join {
    display: none;
    @include phone {
      display: block;
      text-align: center;
      padding-top: 101px;
      z-index: 1100; } }
  &__title {
    color: $introTitleColor;
    padding-top: 164px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 auto;
    @include phone {
      padding-top: 19px;
      font-size: 24px;
      margin: 0;
      line-height: 1.208em; }
    @include tablet {
      font-size: 36px;
      line-height: 1.222em; }
    @include desktop {
      font-size: 64px;
      line-height: 1.219em; } } }

